import React from 'react'
import "../css/capsule.css"

const editcapsules = ({name , value}) => {

  return (
    <div className='capsule_container' >
    <div className='capsule_head'>{name}</div>
    <div className='capsule_count'>
    <input
      className={'_input'}
      style={{ cursor: 'pointer' }}
      id="programYTDCount"
      name="programYTDCount"
      type={'Text'}
      value={value ? value : 0}
    />
    
    {value ? value : 0} ---</div>
  </div>
  )
}

export default editcapsules
