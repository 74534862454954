import axios from 'axios';
import React, { useEffect, useState } from 'react';
import configKeys from '../config/keys';
import inputValidation from '../constants/inputValidaation';
import './containers/css/dashbord.css';
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import DashbordYTD from './containers/DashbordYTD';
import DashbordQTD from './containers/DashbordQTD';
import Editcapsules from './containers/dashbord/editcapsules';
import Chart from 'react-apexcharts'

const FreezeFunderData = (props) => {
  const userDetails = props.userData
  const user = props.user
  const mHistory = useNavigate();

  const month = { '1': 'January, February, March' ,
    '4': 'April, May, June' ,
    '7': 'July, August, September' ,
    '10': 'October, November, December' };
    
  const [selectedOption, setSelectedOption] = useState('');
  const [months, setMonths] = useState([]);
  const [funderType, setFunderType] = useState('');
  const [dashbordDetails, setdashbordDetails] = useState('');
  const [dashbordDetailsByQuarter, setdashbordDetailsByQuarter] = useState('');
  const [funderList, setFunderList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [topDistrict, setTopDistrict] = useState([]);
  // const [topBlocks, setTopBlocks] = useState([]);
  // const [districtList, setDistrictList] = useState([]);
  // const [blockList, setBlockList] = useState([]);
  // const [stateList, setstateList] = useState([]);
  const [formData, setformData] = useState({
    year: '',
    quarter: '',
    state_id: [],
    district_id: '',
    districtName: '',
    block_id: '',
    blockName: '',
    curMonthYear: '',
    startMonthYear: '',
    allState: true,
  });
  const [fromDataError, setFromDataError] = useState({
    year: '',
    quarter: '',
    state_id: '',
    district_id: '',
    curMonthYear: '',
  });

  // useEffect(() => {
  //   getStateList();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const token = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    if (!user || user === null) {
      return mHistory('/login');
    }
    if (!token) return mHistory('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    getFunderList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedOption) {
      if (months.length === 0) {
        let startMonth = parseInt(selectedOption['programStartMonth'])
        let startYear = selectedOption['programStartYear']
        let endMonth = parseInt(selectedOption['programEndMonth'])
        let endYear = selectedOption['programEndYear']
        const date = new Date();
        const curYear = date.getFullYear()
        const curMonth = date.getMonth()+1
        let tempmonths = []
        let quarter = 1
        if (startYear === endYear) {
          for (let i=startMonth;i<=endMonth;i=i+3) {
            if (parseInt(startYear) < parseInt(curYear)) {
              tempmonths.push({name:month[i.toString()]+' - '+startYear,value:quarter+"-"+i+"-"+startYear})
            } else if (parseInt(startYear) === parseInt(curYear) && i <= curMonth) {
              tempmonths.push({name:month[i.toString()]+' - '+startYear,value:quarter+"-"+i+"-"+startYear})
            }
            quarter = quarter + 1
          }
        } else {
          let tempYear = parseInt(startYear)
          while (tempYear <= parseInt(endYear)) 
          { 
            let tempStartMonth = 1
            let tempEndMonth = 12
            if (tempYear === parseInt(startYear)) {
              tempStartMonth = startMonth
            }
            if (tempYear === parseInt(endYear)) {
              tempEndMonth = endMonth
            }
            for (let i=tempStartMonth;i<=tempEndMonth;i=i+3) {
              if ((parseInt(tempYear) === parseInt(curYear) && i <= curMonth) || (parseInt(tempYear) < parseInt(curYear))) {
                tempmonths.push({name:month[i.toString()]+' - '+tempYear,value:quarter+"-"+i+"-"+tempYear})
                quarter = quarter + 1
              }
            }
            tempYear = tempYear + 1
          }
        }
        setMonths(tempmonths)
        setformData({
          ...formData,
          curMonthYear: tempmonths[0]['value'],
          startMonthYear: startMonth+"-"+startYear,
        });
      }
      getDasbordDetails();
      getDasbordDetailsByQuarter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, formData.curMonthYear]);

  const getDasbordDetails = async () => {
    try {
      if (checkIfError()) {
        return;
      }
      let dashbordData = {
        funderId: (userDetails.admintype === "superadmin" || userDetails.admintype === "program_manager" || userDetails.admintype === "admin") ? selectedOption['value'] : userDetails.funderId,
        startMonthYear: formData.startMonthYear,
        curMonthYear: formData.curMonthYear,
      };
      const { data } = await axios.post(`${configKeys.SERVER_URL}/v3/dashboardFunder`, { ...dashbordData }, { headers: { "Authorization": user?.token } });
      setdashbordDetails(data.data);
    } catch (error) {
      console.log(error)
    }
  };

  const getDasbordDetailsByQuarter = async () => {
    try {
      if (checkIfError()) {
        return;
      }
      let dashbordData = {
        funderId: (userDetails.admintype === "superadmin" || userDetails.admintype === "program_manager" || userDetails.admintype === "admin") ? selectedOption['value'] : userDetails.funderId,
        startMonthYear: formData.startMonthYear,
        curMonthYear: formData.curMonthYear,
      };
      const { data } = await axios.post(`${configKeys.SERVER_URL}/v3/dashboardFunderByQuarter`, { ...dashbordData }, { headers: { "Authorization": user?.token } });
      setdashbordDetailsByQuarter(data.data);
    } catch (error) {
      console.log(error)
    }
  };

  const getFunderList = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`${configKeys.SERVER_URL}/v3/funderGroupList?label=true`)
      setFunderList(data.data)
      if (data.data.length === 0) return mHistory('/funderGroup');
      setSelectedOption(data?.data[0])
      if (data?.data[0]['science'] === true && data?.data[0]['fln'] === false)
        setFunderType('SCIENCE')
      else
        setFunderType('FLN')
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const checkIfError = () => {
    let hasError = false; // False means the form has no error and it can be submitted
    let tempError = {
      state_id: '',
    };
    if (inputValidation.isInputHasLength(selectedOption) !== '') {
      tempError.state_id = inputValidation.isInputHasLength(formData.state_id);
      if (formData.allState) {
        hasError = false;
        tempError.state_id = ''
      } else hasError = true;
    }
    setFromDataError({
      ...fromDataError,
      ...tempError,
    });
    console.log({ hasError });
    return hasError;
  };

  const handleChange = (selected) => {
    setSelectedOption(selected)
    if (selected['science'] === true && selected['fln'] === false)
      setFunderType('SCIENCE')
    else
      setFunderType('FLN')

    if (selected) {     
      let startMonth = parseInt(selected['programStartMonth'])
      let startYear = selected['programStartYear']
      let endMonth = parseInt(selected['programEndMonth'])
      let endYear = selected['programEndYear']
      const date = new Date();
      const curYear = date.getFullYear()
      const curMonth = date.getMonth()+1
      let tempmonths = []
      let quarter = 1
      if (startYear === endYear) {
        for (let i=startMonth;i<=endMonth;i=i+3) {
          if (parseInt(startYear) < parseInt(curYear)) {
            tempmonths.push({name:month[i.toString()]+' - '+startYear,value:quarter+"-"+i+"-"+startYear})
          } else if (parseInt(startYear) === parseInt(curYear) && i <= curMonth) {
            tempmonths.push({name:month[i.toString()]+' - '+startYear,value:quarter+"-"+i+"-"+startYear})
          }
          quarter = quarter + 1
        }
      } else {
        let tempYear = parseInt(startYear)
        while (tempYear <= parseInt(endYear)) 
        { 
          let tempStartMonth = 1
          let tempEndMonth = 12
          if (tempYear === parseInt(startYear)) {
            tempStartMonth = startMonth
          }
          if (tempYear === parseInt(endYear)) {
            tempEndMonth = endMonth
          }
          for (let i=tempStartMonth;i<=tempEndMonth;i=i+3) {
            if ((parseInt(tempYear) === parseInt(curYear) && i <= curMonth) || (parseInt(tempYear) < parseInt(curYear))) {
              tempmonths.push({name:month[i.toString()]+' - '+tempYear,value:quarter+"-"+i+"-"+tempYear})
              quarter = quarter + 1
            }
          }
          tempYear = tempYear + 1
        } 
      }
      setMonths(tempmonths)
      setformData({
        ...formData,
        curMonthYear: tempmonths[0]['value'],
        startMonthYear: startMonth+"-"+startYear,
      });
    }
  };

  return (
    <div className="container">
      {dashbordDetails ? (

        <div className="col-12 row mb-5">
          {/* {part 1} */}
          {/* Program Investment */}
          <div className='section_1'>
            <div className='d-flex justify-content-between flex-column'>
              <div className='section_1_year'>
                {dashbordDetailsByQuarter ? dashbordDetailsByQuarter.ytdname : ''}
              </div>
              <div className='section_1_head'>
                Program Investment
              </div>
            </div>

            <div className='' >
              <div className='d-flex justify-content-end'>
                {(userDetails.admintype === "superadmin" || userDetails.admintype === "program_manager" || userDetails.admintype === "admin") ? (
                  <div className={'_input_container'}>
                    <label className={'_label'}>Select Program</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      //  defaultValue={funderList && funderList[0] }
                      value={selectedOption}
                      isLoading={isLoading}
                      isClearable={false}
                      isSearchable={true}
                      name="color"
                      options={funderList}
                      onChange={handleChange}
                    />
                    {fromDataError.state_id && (
                      <span className="text-danger">{fromDataError.state_id}</span>
                    )}
                  </div>
                ) :
                  <div>
                    <div className={'_input_container'}>
                      <label className={'_label'}>&nbsp;</label>
                    </div>
                    <div className={'_input_container'}>
                      <label className={'_label'}>&nbsp;</label>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          {/* YTD DASHBOARD */}
          <>
        <div className='section_2a' >
          <div>
            <span>Program Expenses (YTD) (in Lakhs)</span>
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
          </div>
          
          <div>
            <span>Program Expenses % of Annual Budget (YTD)</span>
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDPercent"
              name="programYTDPercent"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDPercent}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDPercent: t.target.value,
                });
              }}
            />
          </div>
          <div>
            <span>Kits Distributed</span>
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="kitsDistributed"
              name="kitsDistributed"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticYTDData?.kitsDistributed}
              onChange={(t) => {
                setformData({
                  ...formData,
                  kitsDistributed: t.target.value,
                });
              }}
            />
          </div>
          <div>
            <span>No. Of Sparks</span>
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="spark"
              name="spark"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.spark}
              onChange={(t) => {
                setformData({
                  ...formData,
                  spark: t.target.value,
                });
              }}
            />
          </div>
          <div>
            <span>Sampark TV / LED distributed</span>
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDPercent"
              name="programYTDPercent"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticYTDData?.stvLed}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDPercent: t.target.value,
                });
              }}
            />
          </div>
          </div>
          <div className='section_2b' >
            <Editcapsules name={"No. of schools in \nthe state"} value={dashbordDetailsByQuarter?.staticYTDData?.totalSchools} />
            <Editcapsules name={'% of schools covered'} value={dashbordDetailsByQuarter?.staticYTDData?.schoolsCoveredPercent+' %'} />
            <Editcapsules name={'No. of students in\nthe State'} value={dashbordDetailsByQuarter?.staticYTDData?.totalStudents} />
            <Editcapsules name={'% of students covered'} value={dashbordDetailsByQuarter?.staticYTDData?.studentsCoveredPercent+' %'} />
          </div>
          {/* Teacher Training */}
          <div className='section_3'>
            <div className='section_3a'>
              <div className='section_3_head_a_container' >
                <div className='section_3_head1'>Teacher Training</div>
              </div>
              <div className='section_3_head_b_container'>
                <div className='section_3_head1'>
                  Teaching Acceptance Measurement
                </div>
              </div>
            </div>
            <div className='section_3b'>
              <div className='section_3_b1' >
                <Editcapsules name={"Teachers trained"} value={dashbordDetailsByQuarter?.dashboardData?.teachers_trained_ytd || (dashbordDetailsByQuarter?.staticYTDData?.teachers_trained)} />
                <Editcapsules name={"MT Trained"} value={dashbordDetailsByQuarter?.dashboardData?.mt_trained_ytd || (dashbordDetailsByQuarter?.staticYTDData?.mt_trained)} />
              </div>
              <div className='section_3b_container' >
                <Editcapsules name={"No of feedbacks"} value={dashbordDetailsByQuarter?.dashboardData?.no_of_feedbacks_ytd || (dashbordDetailsByQuarter?.previousData['no_of_feedbacks_qtd'] + dashbordDetailsByQuarter?.schoolData['total_feedback'] + dashbordDetailsByQuarter?.meetingFeedback['total_feedback'] + dashbordDetailsByQuarter?.trainingData['total_feedback'])} />
                <Editcapsules name={"Training acceptance"} value={`${dashbordDetailsByQuarter?.dashboardData?.training_acceptance_ytd || dashbordDetails?.trainingData['rating']}`.slice(0, 3) + "/5"} />
                <Editcapsules name={"Program acceptance"} value={`${dashbordDetailsByQuarter?.dashboardData?.program_acceptance_ytd || dashbordDetails?.schoolData['rating']}`.slice(0, 3) + "/5"} />
              </div>
            </div>
          </div>
          {/* classroom impact measured */}
          <div className='section_4' >
            <div className='section_4a'>
              <div className='section_3_head1'>
                Classroom Impact Measured
              </div>
            </div>
            <div className='section_4b'>
              <div className='section_4_card_1' >
                <div className='card_head'>Registered Users</div>
                <div className='card_head_2'>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
                {dashbordDetailsByQuarter?.dashboardData?.registered_users_ytd || (dashbordDetailsByQuarter?.previousData?.registered_users_qtd + dashbordDetailsByQuarter?.registeredUsers?.users_registered)}<span>{` (YTD)`}</span>
                
                </div>
                <div className='card_head_3'><div>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              {dashbordDetailsByQuarter?.dashboardData?.registered_users_Since_Inception || ((dashbordDetails?.inceptionData?.registered_users || 0) + dashbordDetailsByQuarter?.previousData?.registered_users_qtd + dashbordDetailsByQuarter?.registeredUsers?.users_registered)}</div> <span> {`(Since Inception)`}</span></div>
              </div>
              <div className='section_4_card_2' >
                <div className='card_head'>Total Resources Used</div>
                <div className='card_head_2'>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              {dashbordDetailsByQuarter?.dashboardData?.total_resources_used_ytd || (dashbordDetailsByQuarter?.previousData?.total_resources_used_qtd + dashbordDetailsByQuarter?.resourcesUsedTotal)}<span>{` (YTD)`}</span></div>
                <div className='card_head_3'><div>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              {dashbordDetailsByQuarter?.dashboardData?.total_resources_used_Since_Inception || ((dashbordDetails?.inceptionData?.total_resources_used || 0) + dashbordDetailsByQuarter?.previousData?.total_resources_used_qtd + dashbordDetailsByQuarter?.resourcesUsedTotal)}</div> <span> {`(Since Inception)`}</span></div>
              </div>
              <div className='section_4_card_3' >
                <div className='card_head'>Subject Resources Used (YTD)</div>
                <div className='card_3_line_1'>
                  <div className='card_2_lable' >English</div>
                  <div className='card_2_value'>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              {dashbordDetailsByQuarter?.dashboardData?.english_resources_used_ytd || (dashbordDetailsByQuarter?.previousData['english_resources_used_qtd'] + dashbordDetailsByQuarter?.resourcesUsed['english'])}</div>
                </div>
                <div className='card_3_line_2' >
                  <div className='card_2_lable'>Math</div>
                  <div className='card_2_value'>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              {dashbordDetailsByQuarter?.dashboardData?.math_resources_used_ytd || (dashbordDetailsByQuarter?.previousData['math_resources_used_qtd'] + dashbordDetailsByQuarter?.resourcesUsed['math'])}</div>
                </div>
                <div className='card_3_line_3' >
                  <div className='card_2_lable'>Science</div>
                  <div className='card_2_value'>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              {dashbordDetailsByQuarter?.dashboardData?.science_resources_used_ytd || (dashbordDetailsByQuarter?.previousData['science_resources_used_qtd'] + dashbordDetailsByQuarter?.resourcesUsed['science'])}</div>
                </div>
                <div className='card_3_line_4' >
                  <div className='card_2_lable'>Other Subjects</div>
                  <div className='card_2_value'>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              {dashbordDetailsByQuarter?.dashboardData?.other_subject_resources_used_ytd || (dashbordDetailsByQuarter?.previousData['other_subject_resources_used_qtd'] + dashbordDetailsByQuarter?.resourcesUsed['otherSubjects'])}</div>
                </div>
              </div>
              <div className='section_4_card_3' >
                <div className='card_head'>Class Resources Used (YTD)</div>
                <div className='card_2_line_1' >
                  <div className='card_2_lable'>FLN</div>
                  <div className='card_2_value'>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              {dashbordDetailsByQuarter?.dashboardData?.fln_resources_used_ytd || (dashbordDetailsByQuarter?.previousData?.fln_resources_used_qtd + dashbordDetailsByQuarter?.resourcesUsed['FLN'])}</div>
                </div>
                <div className='card_2_line_2'>
                  <div className='card_2_lable'>Class 6- 8</div>
                  <div className='card_2_value'>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              {dashbordDetailsByQuarter?.dashboardData?.class68_resources_used_ytd || (dashbordDetailsByQuarter?.previousData?.class68_resources_used_qtd + dashbordDetailsByQuarter?.resourcesUsed['class6_8'])}</div>
                </div>
                <div className='card_2_line_3'>
                  <div className='card_2_lable'>Other</div>
                  <div className='card_2_value'>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              {dashbordDetailsByQuarter?.dashboardData?.otherclass_resources_used_ytd || (dashbordDetailsByQuarter?.previousData?.otherclass_resources_used_qtd + dashbordDetailsByQuarter?.resourcesUsed['otherClass'])}</div>
                </div>
              </div>
            </div>
          </div>

          {/* Program monitoring evaluation */}
          <div className='section_5' >
            <div className='section_4a'>
              <div className='section_3_head1'>Program Monitoring Evaluation</div>
            </div>
            <div className='section_5b' >
              <div className='section_5_capsules'>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
                <Editcapsules name={"School audit and review"} value={dashbordDetailsByQuarter?.dashboardData?.school_audit_and_review_ytd || (dashbordDetailsByQuarter?.previousData?.school_audit_and_review_qtd + dashbordDetailsByQuarter?.schoolVisits?.visitcount - dashbordDetailsByQuarter?.managerSchoolVIsits?.visitcount + dashbordDetailsByQuarter?.meetingData?.meeting_count - dashbordDetailsByQuarter?.managerMeetingData?.meeting_count)} />
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
                <Editcapsules name={"State advocacy Meeting"} value={dashbordDetailsByQuarter?.dashboardData?.state_advocacy_meeting_ytd || (dashbordDetailsByQuarter?.previousData?.state_advocacy_meeting_qtd + dashbordDetailsByQuarter?.managerSchoolVIsits?.visitcount + dashbordDetailsByQuarter?.managerMeetingData?.meeting_count)} />
              </div>
              <div>  
                {dashbordDetailsByQuarter?.abcReportYTD && (
                  <Chart options={dashbordDetailsByQuarter?.abcReportYTD?.options} series={dashbordDetailsByQuarter?.abcReportYTD?.series} type='pie' width={580} height={200} />
                )}  
                {!dashbordDetailsByQuarter?.abcReportYTD && dashbordDetails?.abcReport && (
                  <Chart options={dashbordDetails?.abcReport?.options} series={dashbordDetails?.abcReport?.series} type='pie' width={580} height={200} />
                )}
              </div>
            </div>
          </div>
        </>
          {/* YTD DASHBOARD */}
        </div>
      ) : (
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only"></span>
        </div>
      )}
      <div className='border_bottom_line' >

      </div>
      {/* part 2 */}
      <div className="col-12 row mb-5">
        {/* Program Investment */}
        <div className='section_1'>
          <div>
            <div className='section_1_year'>
              {dashbordDetailsByQuarter ? dashbordDetailsByQuarter.qtdname : ''}
            </div>
            <div className='section_1_head'>
              Program Investment
            </div>
          </div>
          <div className='section_1_btn'>
            <div className={'_input_container'}>
              {fromDataError.year && (
                <span className="text-danger">{fromDataError.year}</span>
              )}
            </div>
            <div className={'_input_container'}>
              <label className={'_label'}>Select Quarter </label>
              <select
                className={'_input'}
                style={{ cursor: 'pointer' }}
                id="month"
                name="month"
                value={formData.curMonthYear}
                onChange={(t) => {
                  setformData({
                    ...formData,
                    curMonthYear: t.target.value,
                  });
                }}
              >
                {months.map((item) => (
                  <option value={item.value}>{item.name}</option>
                ))}
              </select>
              {fromDataError.curMonthYear && (
                <span className="text-danger">{fromDataError.curMonthYear}</span>
              )}
            </div>
          </div>
        </div>
      
        {/* QTD DASHBOARD START */}
        <>
        <div className='section_2a' style={{paddingBottom:'50px'}}>
          <Editcapsules name={"Program Expenses (QTD)\n(in Lakhs)"} value={dashbordDetailsByQuarter?.staticData?.programCount} />
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
          <Editcapsules name={"Program Expenses \n% of Annual Budget (QTD)"} value={dashbordDetailsByQuarter?.staticData?.programPercent+' %'} />
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
          <Editcapsules name={'Kits Distributed'} value={dashbordDetailsByQuarter?.staticData?.kitsDistributed} />
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
          <Editcapsules name={'No. Of Sparks'} value={dashbordDetailsByQuarter?.staticData?.spark} />
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
          <Editcapsules name={'Sampark TV/LED distributed'} value={dashbordDetailsByQuarter?.staticData?.stvLed} />
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
        </div>
        {/* Teacher Training */}
        <div className='section_3' style={{paddingBottom:'60px'}}>
          <div className='section_3a'>
            <div className='section_3_head_a_container' >
              <div className='section_3_head1'>Teacher Training</div>
            </div>
            <div className='section_3_head_b_container'>
              <div className='section_3_head1'>
                Teaching Acceptance Measurement
              </div>
            </div>
          </div>
          <div className='section_3b'>
            <div className='section_3_b1' >
              <Editcapsules name={"Teachers trained"} value={dashbordDetailsByQuarter?.dashboardData?.teachers_trained_qtd || dashbordDetailsByQuarter?.staticData?.teachers_trained} /*{dashbordDetailsByQuarter?.teacherData?.trained_teachers}*/ />
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
              <Editcapsules name={"MT Trained"} value={dashbordDetailsByQuarter?.dashboardData?.mt_trained_qtd || dashbordDetailsByQuarter?.staticData?.mt_trained} /*{dashbordDetailsByQuarter?.mtteacherData?.trained_teachers} */ />
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
            </div>
            <div className='section_3b_container' >
              <Editcapsules name={"No of Feedbacks"} value={dashbordDetailsByQuarter?.dashboardData?.no_of_feedbacks_qtd || (dashbordDetailsByQuarter?.schoolData?.total_feedback + dashbordDetailsByQuarter?.meetingFeedback?.total_feedback + dashbordDetailsByQuarter?.trainingData?.total_feedback)} />
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
              <Editcapsules name={"Training Acceptance"} value={`${dashbordDetailsByQuarter?.dashboardData?.training_acceptance_qtd || dashbordDetailsByQuarter?.trainingData?.rating}`.slice(0, 3) + "/5"} />
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
              <Editcapsules name={"Program acceptance"} value={`${dashbordDetailsByQuarter?.dashboardData?.program_acceptance_qtd || dashbordDetailsByQuarter?.schoolData?.rating}`.slice(0, 3) + "/5"} />
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
            </div>
          </div>
        </div>
        {/* classroom impact measured */}
        <div className='section_4' style={{paddingBottom:'60px'}}>
          <div className='section_4a'>
            <div className='section_3_head1'>
              Classroom Impact Measured
            </div>
          </div>
          <div className='section_4b'>
            <div className='section_4_card_1' >
              <div className='card_head'>Registered Users</div>
              <div className='card_head_2' style={{ padding: "33.5px 35px " }} >
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              {dashbordDetailsByQuarter?.dashboardData?.registered_users_qtd || dashbordDetailsByQuarter?.registeredUsers?.users_registered} (QTD)</div>
              <div className='card_head_3' style={{ padding: "20px 35px " }} >
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              {dashbordDetailsByQuarter?.dashboardData?.registered_users_ytd || (dashbordDetailsByQuarter?.previousData?.registered_users_qtd + dashbordDetailsByQuarter?.registeredUsers?.users_registered)} (YTD)</div>
            </div>
            <div className='section_4_card_2' >
              <div className='card_head'>Total Resources Used</div>
              <div className='card_head_2' style={{ padding: "33.5px 35px " }} >
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              {dashbordDetailsByQuarter?.dashboardData?.total_resources_used_qtd || dashbordDetailsByQuarter?.resourcesUsedTotal} (QTD)</div>
              <div className='card_head_3' style={{ padding: "20px 35px " }} >
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              {dashbordDetailsByQuarter?.dashboardData?.total_resources_used_ytd || (dashbordDetailsByQuarter?.previousData?.total_resources_used_qtd + dashbordDetailsByQuarter?.resourcesUsedTotal)} (YTD)</div>
            </div>
            <div className='section_4_card_3' >
              <div className='card_head'>Subject Resources Used (QTD)</div>
              <div className='card_3_line_1'>
                <div className='card_2_lable' >English</div>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
                <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.english_resources_used_qtd || dashbordDetailsByQuarter?.resourcesUsed?.english}</div>
              </div>
              <div className='card_3_line_2' >
                <div className='card_2_lable'>Math</div>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
                <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.math_resources_used_qtd || dashbordDetailsByQuarter?.resourcesUsed?.math}</div>
              </div>
              <div className='card_3_line_3' >
                <div className='card_2_lable'>Science</div>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
                <div className='card_2_value'>{(dashbordDetailsByQuarter?.dashboardData?.science_resources_used_qtd || dashbordDetailsByQuarter?.resourcesUsed?.science)}</div>
              </div>
              <div className='card_3_line_4' >
                <div className='card_2_lable'>Other Subjects</div>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
                <div className='card_2_value'>{(dashbordDetailsByQuarter?.dashboardData?.other_subject_resources_used_qtd || dashbordDetailsByQuarter?.resourcesUsed?.otherSubjects)}</div>
              </div>
            </div>
            <div className='section_4_card_3' >
              <div className='card_head'>Class Resources Used (QTD)</div>
              <div className='card_2_line_1' >
                <div className='card_2_lable'>FLN</div>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
                <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.fln_resources_used_qtd || dashbordDetailsByQuarter?.resourcesUsed?.FLN}</div>
              </div>
              <div className='card_2_line_2'>
                <div className='card_2_lable'>Class 6-8</div>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
                <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.class68_resources_used_qtd || dashbordDetailsByQuarter?.resourcesUsed?.class6_8}</div>
              </div>
              <div className='card_2_line_3'>
                <div className='card_2_lable'>Other</div>
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
                <div className='card_2_value'>{dashbordDetailsByQuarter?.dashboardData?.otherclass_resources_used_qtd || dashbordDetailsByQuarter?.resourcesUsed?.otherClass}</div>
              </div>
            </div>
          </div>
        </div>

        {/* Program monitoring evaluation */}
        <div className='section_5' >
          <div className='section_4a'>
            <div className='section_3_head1'>Program Monitoring Evaluation</div>
          </div>
          <div className='section_5b' >
            <div className='section_5_capsules'>
              <Editcapsules name={"School audit and review"} value={dashbordDetailsByQuarter?.dashboardData?.school_audit_and_review_qtd || (dashbordDetailsByQuarter?.schoolVisits?.visitcount - dashbordDetailsByQuarter?.managerSchoolVIsits?.visitcount + dashbordDetailsByQuarter?.meetingData?.meeting_count - dashbordDetailsByQuarter?.managerMeetingData?.meeting_count)} />
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
              <Editcapsules name={"State advocacy Meeting"} value={dashbordDetailsByQuarter?.dashboardData?.state_advocacy_meeting_qtd || (dashbordDetailsByQuarter?.managerSchoolVIsits?.visitcount + dashbordDetailsByQuarter?.managerMeetingData?.meeting_count)} />
                
                
            <input
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="programYTDCount"
              name="programYTDCount"
              type={'Text'}
              value={dashbordDetailsByQuarter?.staticData?.programYTDCount}
              onChange={(t) => {
                setformData({
                  ...formData,
                  programYTDCount: t.target.value,
                });
              }}
            />
                
              
            </div>
            <div>
              {dashbordDetailsByQuarter?.abcReport && (
                <Chart options={dashbordDetailsByQuarter?.abcReport?.options} series={dashbordDetailsByQuarter?.abcReport?.series} type='pie' width={580} height={200} />
              )}
            </div>
          </div>
        </div>
      
        </>
        {/* QTD DASHBOARD */}
        
      </div>
    </div>
  );
};

const mapStateStore = (stateStore) => {
  return {
    user: stateStore.userState.user,
    userData: stateStore.userState.userDetails,

  };
};

export default connect(mapStateStore)(FreezeFunderData);

//  block and district drop-down
// <div className={'_input_container'}>
// <label className={'_label'}>Select District </label>
// <select
//   className={'_input'}
//   style={{ cursor: 'pointer' }}
//   id="year"
//   name="year"
//   disabled
//   // disabled={
//   //   formData.state_id?.length === 1 ||
//   //   formData.year !== '2021-2022'
//   //     ? false
//   //     : true
//   // }
//   value={formData.district_id}
//   onChange={(t) => {
//     setformData({
//       ...formData,
//       district_id: t.target.value,
//       districtName: t.target.selectedOptions[0].text,
//     });
//   }}
// >
//   <option value="" selected>
//     All District
//   </option>
//   {districtList.map((item) => (
//     <option id={item.name} value={item._id}>
//       {item.name}
//     </option>
//   ))}
// </select>
// {/* {formDataError.year && (
// <span className="text-danger">{formDataError.year}</span>
// )} */}
// </div>
// <div className={'_input_container'}>
// <label className={'_label'}>Select Blocks</label>
// <select
//   className={'_input'}
//   style={{ cursor: 'pointer' }}
//   id="year"
//   name="year"
//   disabled
//   // disabled={formData.state_id.length === 0 ? true : false}
//   value={formData.block_id}
//   onChange={(t) => {
//     setformData({
//       ...formData,
//       block_id: t.target.value,
//       blockName: t.target.selectedOptions[0].text,
//     });
//   }}
// >
//   <option value="" selected>
//     Select
//   </option>
//   {blockList.map((item) => (
//     <option id={item.name} value={item._id}>
//       {item.name}
//     </option>
//   ))}
// </select>
// {/* {formDataError.year && (
// <span className="text-danger">{formDataError.year}</span>
// )} */}
// </div>
